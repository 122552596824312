<template>
  <order :pagination="pagination" ref="order"></order>

</template>

<script>

  import Order from '@/components/Order/shop'

  export default {
    name: '',
    components: {
      Order
    },
    mounted() {
      this.$refs.order.loadOrder()
    },
    data: function () {
      return {
        pagination: {
        },
      }
    },

    methods: {},
  }
</script>
